import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, BodyText1, styleForColumn} from "./rr_style"

import { useInView } from 'react-intersection-observer'

export const RRContribution = (props) => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
        <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}>
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                    My Contribution
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem"}}>
             <Col md={{size:6, offset:5}} style={styleForColumn}>
                <BodyText1>
                This project is set to have a large portion of user research. With my background in Psychology, I was heavily involved in planning and conducting research. I also contributed to running 
                design activities and providing design recommendation based on my design experience and user feedback.  
                </BodyText1>
            </Col>

            <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}/>
        </Row>
        </React.Fragment>
    )

}