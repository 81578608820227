import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide} from "./rr_style"

import rrOverview from "../../images/rr/RROverview.png"

export const RRIntro = (props) => {

    return (
        <Row>

            {/* Need to find a better solution to this look */}
            <Col sm={{size: 10, offset:1}} style={{display:"flex", flexDirection:"column", paddingLeft: `${paddingOneSide}px`, paddingRight: `${paddingOneSide}px`}}>
                <RRH1>
                    Focus Brands Research Repository
                </RRH1>
                
                <Subtitle1 style={{marginTop:"16px", fontWeight: 300}}>
                    Collaborated with Yi He, Xiuxiu Yuan, Josiah Mangiameli 
                </Subtitle1>

                <Subtitle1 style={{marginTop:"8px", fontWeight: 300}}>
                    Aug. 2018 - Dec. 2018
                </Subtitle1>

                <H4 style={{marginTop: 50}}>
                    The web based research repository that helps share better research among employees and managers in Focus Brands. 
                </H4>

                <img src={rrOverview} style={{marginTop:"60px"}}>

                </img>
            </Col>            
        </Row>
    )
}


