import React, {useRef} from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, BodyText2, paddingOneSide, styleForColumn, Caption, Subtitle1OpenSansWithLightColor} from "./rr_style"
import {ZoomableImageWithModal} from "./rr_common_components"

import { useInView } from 'react-intersection-observer'
import wireframe from "../../images/rr/Wireframe.jpg"
import mapView from "../../images/rr/R4_Prototype-interactiveView.mp4";
import upload from "../../images/rr/R4_Upload.mp4"
import loginSearch from "../../images/rr/R4_Prototype-login-search-regularview.mp4"
import quickAccessVideo from "../../images/rr/R4_QuickAccess-AddOneDrive.mp4"

const videoColumnTopMargin = {
     marginTop:"2rem" , ...styleForColumn
}
 
export const RRPrototype = () => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
        <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}> 
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                    Prototype
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem", position:"relative"}}>
            {/* Row 1 */}
            <Col md={{size:4, offset: 1}} style={styleForColumn}>
                <ZoomableImageWithModal image={wireframe} header={"Wireframe"}/>
            </Col>

            <Col md={{size:6}} style={styleForColumn}>
                <BodyText1>
                    Based on the user flow, we quickly drew a wireframe with use cases in our mind. The wireframe represents how we translate our features into screens and helps us determine our information architecture. Our users who went through our prototypes while mostly agreeing with our screens decision, suggest that they don’t find our filter designed useful and fit in their workflow. 
                </BodyText1>
            </Col>

            <Col md={{size: 1}}/>

            {/* Row 2 */}
            <Col md={{size:6, offset: 5}} style={styleForColumn}>
                <BodyText1 style={{marginBottom:"1rem"}}>
                    Without knowing how employees will group different files, we also working on a <strong>open card-sorting event activity</strong> for those who tested our prototypes to understand how employees would group different research files. We found that employees in Focus Brands have <strong>strong tendency</strong> to grouping based on different brands, and also the department. It enables us to create filters that employees would love to see. 
                </BodyText1>
                <BodyText1>
                    Based on some of the feedback. we spent time developing a higher fidelity prototype with some of the key screens and interactions available telling a story of how Johnny Smith uses the tool to share and make decisions based on the tools 
                </BodyText1>
            </Col>
            
            <Col md={{size: 1}} style={styleForColumn}></Col>

            {/* Row 3 */}
            {/* Should I use a gif or video R4_Prototype-interactiveView.mp4 */}
            <Col md={{size: 4, offset: 1}} style={{marginTop:"3rem", ...styleForColumn}}>
                <BodyText2>
                    The first use case we focus on is how Johnny Smith searches for a specific file. He will type in the keywords into the search bar. In the result page, Johnny can either click different tags or pre-set filters on the left hand side to filter. Brands and date uploaded have been top request for filters, and tags are used for distinguishing different functionalities
                </BodyText2>
            </Col>

            <Col md={{size: 6}} style={{marginTop:"3rem", ...styleForColumn}}>
               <video style={{width:"100%"}} loop muted autoPlay>
                <source src={loginSearch} />
               </video>
            </Col>

            <Col md={{size:1}}/>

            {/* Row 4 */}
            <Col md={{size: 4, offset: 1}} style={videoColumnTopMargin}>
                <BodyText2>
                The second use case we focus on is how Johnny Smith adds a research file into the web app. To ensure a relative structure name for research files to later retriving, we allow the computer to generate file names automatically, based on the important basic information provided by Johnny. Johnny also need to add some tags for others to later filter 
                </BodyText2>
            </Col>

            <Col md={{size: 6}} style={videoColumnTopMargin}>
                <video style={{width:"100%"}} loop muted autoPlay>
                    <source src={upload} />
               </video>
            </Col>

            <Col md={{size:1}}/>

              {/* Row 5 */}
            <Col md={{size: 4, offset: 1}} style={videoColumnTopMargin}>
                <BodyText2>
                    The third use case is when Johnny Smith wants to have full overview of the research in this repository, and their relationships, he can click the map view to see the visualization that labels the relationship between different groups. 
                </BodyText2>
            </Col>

            <Col md={{size: 6}} style={videoColumnTopMargin}>
                <video style={{width:"100%"}} loop muted autoPlay>
                    <source src={mapView} />
               </video>
            </Col>

            <Col md={{size:1}}/>

            {/* Row 6 */}
            <Col md={{size: 4, offset: 1}} style={videoColumnTopMargin}>
                <BodyText2>
                The final use case we focus on is how Johnny Smith changes his personal setting in quick access which allows users to get their favourite from any screens. 
                </BodyText2>
            </Col>

            <Col md={{size: 6}} style={videoColumnTopMargin}>
               <video style={{width:"100%"}} loop muted autoPlay>
                    <source src={quickAccessVideo}/>
               </video>
            </Col>

            <Col md={{size:1}}/>

            <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}>
                
            </div>
        </Row>
        </React.Fragment>

    )
}