import React, {useRef} from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn, Caption, Subtitle1OpenSansWithLightColor} from "./rr_style"

import {ZoomableImageWithModal} from "./rr_common_components"
import ideation from "../../images/rr/ideation.png"
import conceptA from "../../images/rr/Concept-A-readjusted.jpg"
import conceptB from "../../images/rr/Concept-B-readjusted.jpg"
import conceptC from "../../images/rr/Concept-C-readjusted.jpg"
import wireframeEvaluation from "../../images/rr/Evaluation.jpg"

import { useInView } from 'react-intersection-observer'

export const RRIdeation = () => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
        <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}> 
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                    Ideation
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem", position: "relative"}}>
                
            <Col md={{size:6, offset:5}} style={styleForColumn}>
                
                <ZoomableImageWithModal image={ideation} header="Brainstorming" style={{width:"75%"}}/> 

                <BodyText1>
                    We kicked off our ideation process by having a brainstorm session hosted by me. Based on our pain points, we synthesized our various ideas three different one. 
                </BodyText1>
            </Col>
        
            <Col md={{size:1}}/>

            {/* Second Row */}
            <Col md={{size:4}} style={{ display:"flex", flexDirection:"column", alignItems:"center" , ...styleForColumn}}>
                <ZoomableImageWithModal image={conceptA} header={"Concept A"} style={{width: "75%"}}/>
                <Caption> Idea 1: File-based central repository </Caption>
            </Col>

            <Col md={{size:4}} style={{ display:"flex", flexDirection:"column", alignItems:"center" , ...styleForColumn}}>
                <ZoomableImageWithModal image={conceptB} header={"Concept B"} style={{width: "75%"}}/>
                <Caption>Idea 2: Visualization and AI</Caption>
            </Col>

            <Col md={{size:4}} style={{ display:"flex", flexDirection:"column", alignItems:"center" , ...styleForColumn}}>
                <ZoomableImageWithModal image={conceptC} header={"Concept C"} style={{width: "75%"}}/>
                <Caption>Idea 3: Personal Board</Caption>
            </Col>
            
            <Col md={{size: 6, offset:5}}>
                <BodyText1 style={{marginTop:"2rem", marginBottom:"2rem"}}>
                    Our first idea — <strong> file-based central repository </strong> — envision a web solution where the traditional folder structure of research files is flattened. The repository will default have all files and employees can use tags to filter different files. 
                </BodyText1>

                <BodyText1 style={{marginBottom:"2rem"}}>
                    Our second idea is to create a <strong> tree visualization </strong> to show the relationships between different files in a direct and easy to understand way. Files with similar traits or tags will be grouped together and users shall customize it based on their needs.
                </BodyText1>

                <BodyText1 style={{marginBottom:"3rem"}}>
                    Our third idea is a solution with <strong> personal research board </strong> similar to Pinterest. Different employees can create their own  personal boards to host different research posts. Managers can subscribe to employees related to their decisions to receive updates  
                </BodyText1> 

                <Subtitle1OpenSansWithLightColor style={{marginBottom: "1rem"}}>
                    Concept evaluation
                </Subtitle1OpenSansWithLightColor>

                <BodyText1>
                    We conducted lightweight user testing with three employees at Forcus Brands on those three ideas, 
                    focusing on received benefits, users’ reaction and their intentions of use. 
                    We found out that in general, employees show more interet and higher agreement on 
                    the first idea, but still have concerns of how to locate files from the repository filled with research files. They find a flattened file structure appealing and fit in what they want. Regarding visualizing, they feel it is too advanced and tech-savvy. They are also keen on the utilizing authors as a way to track research file but deemed the notification feature in the third idea too heavy work. 
                </BodyText1>

                <ZoomableImageWithModal image={wireframeEvaluation} header={"Wireframe Evaluation"} style={{width:"70%"}}>
                    
                </ZoomableImageWithModal>

                <Subtitle1OpenSansWithLightColor style={{marginBottom: "1rem"}}>
                    Key Features
                </Subtitle1OpenSansWithLightColor>

                <BodyText1 style={{marginBottom: "2rem"}}>
                    After the evaluation, we realize that people feel comfortable and show acceptance in using a new file structure scheme, especially the tags. So we choose to use that as our foundational mechanism for organizing files. We also thought of using personal profile and a map view to strengthen this mechanism.
                </BodyText1>

                <BodyText1 style={{marginBottom: "2rem"}}>
                    Combining all those ideas, we settle down on five key features 
                </BodyText1>

                <ul>
                    <li>  
                        <BodyText1>File centered repository with tags</BodyText1>
                    </li>

                    <li>
                        <BodyText1>Recommendation based on personal preferences</BodyText1>
                    </li>

                    <li>
                        <BodyText1> Guided file uploading process </BodyText1>
                    </li>

                    <li>
                        <BodyText1>  Personal profile </BodyText1>
                    </li>

                    <li>
                        <BodyText1> Interactive prototypes </BodyText1>
                    </li>
                </ul>

            </Col>
    
            <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}>
                
            </div>
        </Row>
        </React.Fragment>        
    )
}