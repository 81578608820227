import React, {useRef} from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, BodyText2, paddingOneSide, styleForColumn, Caption, Subtitle1OpenSansWithLightColor} from "./rr_style"
import {ZoomableImageWithModal} from "./rr_common_components"

import { useInView } from 'react-intersection-observer'
import usabilityTesting from "../../images/rr/usabilityTesting.jpg"
import video  from "../../images/rr/R4_Prototype-interactiveView.mp4";

export const RREvaluation = () => {
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
        <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}>
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                    Evaluation
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem", position:"relative"}}>
            {/* Row 1 */}
            <Col md={{size:6, offset: 5}} style={styleForColumn}>
                <ZoomableImageWithModal image={usabilityTesting} header={"Usability"} style={{width:"75%"}}/>

                <BodyText1 style={{marginBottom:"2rem"}}>
                    Wondering the usability of our tools, we consulted 3 UX experts on <strong>a heuristic evaluation</strong> to identify obvious usability problems quickly with 10 Nielson principles. Then we conducted <strong>4 usability testings</strong> with Focus Brands employees formally understand the usability issues of seen by users. Users are asked to complete 5 tasks related to our use cases. 
                </BodyText1>

                <BodyText1 style={{marginBottom:"2rem"}}>
                    As a result of our evaluation, we found our design is overall usable. Our score for System Usabiliity Scale is <strong>over 72.5</strong>, seen as a good usability. <strong>All users accomplished our tasks without significant confusion and delay</strong>.
                </BodyText1>

                <BodyText1 style={{marginBottom:"2rem"}}>
                Some of our identified usability problems through our testing include
                <br/>
	                - a font size too small
                <br/>
	                - somewhat confusion for different scroll mechanism 
                </BodyText1>

                <BodyText1 style={{marginBottom:"2rem"}}>
                Some of questions and problem for our features pointed out by employees in our testing include
                    <br/>
                    - the scalability for tagging and recommendation systems
                    <br/>
	                - need modified guiding uploading process more with the employees

                </BodyText1>
            </Col>
            
            <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}>
                
            </div>
        </Row>
        </React.Fragment>
    )

}