import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./rr_style"

export const RRSolution = () => {

    return (
        <React.Fragment>
        <Row>
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                    Our Solution
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem"}}>
             <Col md={{size:6, offset:5}} style={styleForColumn}>
                <BodyText1>
                    Our solution is a web-based coporate <strong> research shared </strong> software where employees and managers can share their research with a <strong> tag-based </strong> file management. 
                </BodyText1>
            </Col>
        </Row>
        
        </React.Fragment>
    )
}