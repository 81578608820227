import React from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn} from "./rr_style"
import current_issue from "../../images/rr/current_issue.png"

export const RRProblemStatement = (props) => {
    
    return (
        <React.Fragment>
        <Row>
            <Col md={{size:4, offset:1}} style={styleForColumn}>
                
                <H4 style={{position:"relative",top: "50%"}}>
                    Problem Statement
                </H4>

                {/* Image */}

            </Col>

            <Col md={6} style={{display:"flex", ...styleForColumn, flexDirection: "row", justifyContent:"flex-end",}}>
                <img src={current_issue} style={{width:"75%"}}>
                </img>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem"}}>
             <Col md={{size:6, offset:5}} style={styleForColumn}>
                <BodyText1>
                    Focus Brands is a rising company focusing on manaing multiple food brands like Jumba Juice, Moe’s. It comes to us with a request to improve their internal sharing of research files. 
                    As their company acquires more brands, the complexity of communication research files improves dramatically. 
                    Right now, they find their employees and managers difficult to find the research they need in a deep and not well managed shared drive.
                </BodyText1>
            </Col>
        </Row>
        </React.Fragment>
    )
}