import React, {useState} from 'react'
import {
  Row,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap'
import styled, {keyframes} from 'styled-components'
import {BodyText1, BodyText1Span} from "../components/text_style"

const SeperatorCircle = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;
  border-color: #d25709;
  margin-left: 1.875rem;

  &:first-child {
    margin-left: 0;
  }
`

export const SectionSeperator = props => {
  return (
    <Row className={props.className}>
      <Col md={4} />
      <Col
        md={4}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SeperatorCircle />
        <SeperatorCircle />
        <SeperatorCircle />
      </Col>
    </Row>
  )
}

export let ZoomableImage = styled.img`
  padding: 8px;
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 8px;
  &: hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
  }
`

export let HoverAnimatedMainText1Span = styled(BodyText1Span)`
    // background-color: ${"#E2FBF7"};
    background-position: -0% 0;
    background-size: 200% auto;
    background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, #E2FBF7 50%);
    transition: background-position 0.5s ease-out; 
`

export let HoverAnimatedMainText1 = styled(BodyText1Span)`
    // background-color: ${"#E2FBF7"};
    background-position: -0% 0;
    background-size: 200% auto;
    background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, #E2FBF7 50%);
    transition: background-position 0.5s ease-out; 
`


export const AnimatedTextSpan = ({ isVisible, text }) => {
  const backgroundPosition= isVisible ? '-99.99% 0;' : '-0% 0;'
  console.log(backgroundPosition)

  const highlightClass = isVisible? "highlight" : ""

  return (
    <HoverAnimatedMainText1Span className={highlightClass} style={{
      marginBottom: `11px`
    }}> {text} </HoverAnimatedMainText1Span>
  );
}

export const AnimatedText = ({isVisible, text, style={}}) =>{
  const backgroundPosition= isVisible ? '-99.99% 0;' : '-0% 0;'
  console.log(backgroundPosition)

  const highlightClass = isVisible? "highlight" : ""

  return (
    <HoverAnimatedMainText1 className={highlightClass} style={{...style,
      marginBottom: `11px`
    }}> {text} </HoverAnimatedMainText1>
  );
}

export const ZoomableImageWithModal = ({style, image, header, imgStyle={}}) => {

    const [modalOpen, setModalOpen] = useState(false)
    
    const toggle = () => setModalOpen(!modalOpen)

    return (
        <Container style={style}>
          <ZoomableImage src={image} alt={header} style={imgStyle} className={"img-fluid"} onClick={toggle}/>
          <Modal isOpen={modalOpen} size='xl'>
            <ModalHeader toggle={toggle}>{header} </ModalHeader>
            <ModalBody>
              <Container  className="d-flex justify-content-center">
                <img src={image} className={"img-fluid"}/>
              </Container>
            </ModalBody>
          </Modal>
        </Container>
    )
} 

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

const LoaderStyle = styled.div`
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid ${props=>props.color}; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${spin} 2s linear infinite;
`

export const Loader = ({color="#3498db", style={}}) => (
  <LoaderStyle style={style} color={color}></LoaderStyle>
)
  