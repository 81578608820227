import React, {useRef} from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn, Caption} from "./rr_style"
import {ZoomableImageWithModal, AnimatedText} from "./rr_common_components"
import { useInView } from 'react-intersection-observer'

import TrackVisibility from 'react-on-screen';

export const RRDesignGoal = () => {

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })

    return (
        <React.Fragment>
        <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}> 
            <Col md={{size:10, offset:1}} style={{...styleForColumn, alignItems:"center"}}>
                <H4>
                    Design goal 
                </H4>
            </Col>
        </Row>

        <Row style={{marginTop: "1.5rem", position: "relative"}}>
             <Col md={{size:6, offset:3}} style={{...styleForColumn, alignItems:"center"}}>
                <BodyText1 style={{textAlign:"center"}}>
                    Design a web-based research repository which enables employees and management to <strong>take less effort</strong> locate a file and provide enough <strong>context information</strong> for <strong>decision making</strong>.
                    {/* <TrackVisibility style={{display:"span"}}>  
                        <AnimatedText style={{textAlign:"center"}} text={"Design a web-based research repository which enables employees and management to take less effort locate a file and provide enough context information for decision making. "}/>
                    </TrackVisibility> */}
                </BodyText1>
            </Col>

            <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}>
                
            </div>
        </Row>
        </React.Fragment>        
    )
}