import React from 'react'
import {Row, Col, Container, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap'

import styled from 'styled-components'
// import sectionSeperator from ""
import { getColOf3 } from '../text_style'
import {ZoomableImage, ZoomableImageWithModal, AnimatedText, AnimatedTextSpan} from "../site_common_components"


export const SectionSeperator = props => {
    return (
      <Row className={props.className}>
        <Col md={5} />
        <Col
          md={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <SeperatorCircle />
          <SeperatorCircle />
          <SeperatorCircle /> */}

           <div style={{height:1, backgroundColor:"#D25709", width:"50%"}}>
           </div>
        </Col>
      </Row>
    )
  }

const RRSectionSeperator = styled(SectionSeperator)`
    margin-top: 50px;
    margin-bottom: 50px;
`

export {RRSectionSeperator, ZoomableImage, ZoomableImageWithModal, AnimatedText, AnimatedTextSpan}