import React, {useRef} from 'react'
import {Row, Col, Container} from "reactstrap"
import {RRH1, Subtitle1, H4, gutter, BodyText1, paddingOneSide, styleForColumn, Caption} from "./rr_style"

import rrOverview from "../../images/rr/RR-timeline-presentation.png"
import directorJane from "../../images/rr/Jane.jpg"
import employeeWilliam from "../../images/rr/William.jpg"
import affinityDiagram from "../../images/rr/Affinity-diagram-practice.jpg"

import {ZoomableImageWithModal, AnimatedTextSpan, } from "./rr_common_components"

import { useInView } from 'react-intersection-observer'
import TrackVisibility from 'react-on-screen';


export const RRResearch = () => {
    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
        // root:""
    })
    
    return (
        <React.Fragment>
        <Row style={{
                position: "sticky" , top:"40px", zIndex:3, background:"transparent", 
                transition: "opacity 0.2s ease-in", opacity: inView? 1 : 0
            }}>
            <Col md={{size:4, offset:1}} style={{...styleForColumn}}>
                {/* <div style={{background:"white", borderRadius: "8px", position:"relative"}}> */}
                    <H4 style={{background:"white", borderRadius: "8px", position:"relative", padding:"4px", width: "fit-content"}}>
                        Research 
                    </H4>
                {/* </div> */}
            </Col>

            {/* <Col md={{size:6}} style={{...styleForColumn}}>
                 <img src={rrOverview} style={{marginTop:"60px"}}/>
            </Col> */}
        </Row>

        <Row style={{marginTop: "1.5rem", position: "relative"}}>
             <Col md={{size:6, offset:5}} style={styleForColumn}>

                <ZoomableImageWithModal image={rrOverview} header={"Research Plan"} style={{marginTop:"60px"}}/> 

                <BodyText1 style={{marginTop:"14px"}}>
                We conducted extensive research to understand employees’ context and needs. We wanted to know employees’ painpoints in the current research sharing system, and the context when they do it. Tight schedule of employees only allows us to conduct a contextual inquiry on 5 particpiants to understand employees. So we also follow up a survey for over 30 participants to validate the problems we saw. 
                Our competitive analysis leads us to find existing commercial knowledge management systems for employees. 
                </BodyText1>

                <BodyText1 style={{marginTop:"50px"}}>
                In our research, we find that our target users have two types, with different needs and user tasks: 
                 
                {/* <TrackVisibility style={{display:"inline"}}>
                    <AnimatedTextSpan text={"research producer and consumer."}>
                    </AnimatedTextSpan>
                </TrackVisibility> */}

                <strong> research and consumer</strong>.

                Those producers, exemplified by researchers, and market analystists focus on putting their research artifacts into the research, 
                and organizing them. Consumers exemplified by managers and directors focus on quickly retrieving those research artifacts and utilizing those research to make decisions. They both focus on retriving files quickly 
                </BodyText1>

            </Col>

            <Col md={{size: 1}} style={styleForColumn}/>

            <Col md={{size: 5, offset:1}} style={{marginTop:"50px", display:"flex", flexDirection:"column", alignItems:"center", ...styleForColumn}}>
                <ZoomableImageWithModal image={employeeWilliam} header={"Research Producer"}></ZoomableImageWithModal>
                <Caption> Persona for research producer </Caption>
            </Col>
            <Col md={{size: 5}} style={{ marginTop:"50px",display:"flex", flexDirection:"column", alignItems:"center", ...styleForColumn}}>
                <ZoomableImageWithModal image={directorJane} header={"Research Consumer"}></ZoomableImageWithModal>
                <Caption> Persona for research consumer </Caption>
            </Col>
            
            <Col md={{size: 1}} />

            <Col md={{size: 6, offset: 5}} style={{marginTop:"50px"}}>
                <BodyText1>
                While our affinity diagrams and user flow show many painpoints, we decide to focus on three main user pain points in the current system. First, the existing solution of storing research file, aka Microsoft sharing network drive, 
                <strong> lacks file visibility </strong>. The deep file hierachy hides many research files from employees. Second, different brands and departments have <strong> their own research file storage structure, and file naming conventions </strong>, leading to a culture barrier between different teams, some of even using different storage tools (Google drive, One drive, Slack). Finally, we find that many managers and directors complained that the research archifacts they find <strong> do not contain enough context </strong> for them to understand those files and make decisions based on it.
                </BodyText1>
            </Col>

            <Col md={{size: 1}} />

            <Col md={{size: 4, offset : 1}} style={{marginTop: "50px"}}>
                <ZoomableImageWithModal image={affinityDiagram} header={"Affinity Diagram"}/>
            </Col>

            <div style={{height:"70%", width: "10px", background:"transparent", position:"absolute", top: "-20px"}} ref={ref}>
                
            </div>
        </Row>
        </React.Fragment>
    )
}