import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/layout'
import SEO from '../components/seo'
import {RRIntro} from "../components/rr/rr_intro"
import {RRContribution} from "../components/rr/rr_contribution"
import { getSeperatorWithHeight } from "../components/text_style";
import {Container, Row} from "reactstrap"
import { RRSectionSeperator } from "../components/rr/rr_common_components";
import { RRProblemStatement } from "../components/rr/rr_problem_statement";
import { RRSolution } from "../components/rr/rr_solution"
import {RRResearch} from "../components/rr/rr_research"
import {RRDesignGoal} from "../components/rr/rr_design_goal"
import {RRIdeation} from "../components/rr/rr_ideation"
import { RRPrototype } from "../components/rr/rr_prototype"
import {RREvaluation} from "../components/rr/rr_evaluation"
import Footer from './footer'


const ResearchRepository = () =>{

    return (
        // <div>
        //     Hello world
        // </div>
        <Layout>

            <SEO title={"Research Repository Focus Brands"} keywords={['ResearchRepository', "Junjie Xu"]}/>
            
            {getSeperatorWithHeight(50)}
            <Container style={{marginLeft:"0px", marginRight: "0px", maxWidth: "1400px", alignSelf:"center"}}>
                <RRIntro/>

                {getSeperatorWithHeight(50)}
                <RRContribution/>

                <RRSectionSeperator/>

                <RRProblemStatement/>

                <RRSectionSeperator/>

                <RRSolution/>

                <RRSectionSeperator/>

                <RRResearch/>

                <RRSectionSeperator/>
                
                <RRDesignGoal/>

                <RRSectionSeperator/>

                <RRIdeation/>

                <RRSectionSeperator/>

                <RRPrototype/>

                <RRSectionSeperator/>

                <RREvaluation/>

                 {getSeperatorWithHeight(200)}
                <Row className="d-flex justify-content-center">
                    <a style={{color:`#ADADAD`}} href="#">Back to top</a>
                </Row>
                {getSeperatorWithHeight(20)}
                
                <Footer/>                
            </Container>
        </Layout>
    )
}



export default ResearchRepository